import React, { useEffect, useState } from 'react';

import { get } from './network/apiCalls';

import Modal from './Modal';

const ListOfPress = () => {
  const [listOfPress, setListOfPress] = useState([])
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    get('/api/press/fetchAll', (result) => {
      setListOfPress(result.presses);
    });
  }, []);

  const showModal = (index, objKey, data, title, key, id) => {
    setModalShow(true);
    setModalData({ body: data || '', title: title, key: key, id: id, index: index, objKey: objKey });
  }

  const updatePressState = (modalData, data) => {
    const newListOfReport = [...listOfPress];
    newListOfReport[modalData.index][modalData.objKey] = data;
    setModalData({ ...modalData, ['body']: data });
    setListOfPress(newListOfReport);
  }

  return <div>
    <h3>List Of Report</h3>
    <table className="table">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Title</th>
          <th scope="col">Meta Title</th>
          <th scope="col">Meta Description</th>
          <th scope="col">Meta Keywords</th>
          <th scope="col" style={{ "maxWidth": "300px" }}>Body</th>
          <th scope="col" style={{ "maxWidth": "300px" }}>Quote</th>
        </tr>
      </thead>
      <tbody>
        {
          (listOfPress || []).map((press, index) => {
            return <tr key={press.id}>
              <th scope="row">{press.id}</th>
              <th onClick={() => showModal(index, 'title', press.title, 'Title', 'title', press.id)} scope="row">{(press.title || '').substring(0, 200) + '...'}</th>
              <td onClick={() => showModal(index, 'meta_title', press.meta_title, 'Meta Title', 'metaTitle', press.id)}>{press.meta_title}</td>
              <td onClick={() => showModal(index, 'meta_desc', press.meta_desc, 'Meta Description', 'metaDescription', press.id)}>{press.meta_desc}</td>
              <td>{press.meta_keywords}</td>
              <td onClick={() => showModal(index, 'body', press.body, 'Body', 'body', press.id)} style={{ "maxWidth": "300px" }}>{(press.body || '').substring(0, 200) + '...'}</td>
              <td onClick={() => showModal(index, 'quote', press.quote, 'Quote', 'quote', press.id)} style={{ "maxWidth": "300px" }}>{(press.quote || '').substring(0, 200) + '...'}</td>
            </tr>
          })
        }
      </tbody>
    </table>
    <Modal
      show={modalShow}
      modaldataprops={modalData}
      updatereportstate={updatePressState}
      onHide={() => setModalShow(false)}
      url='/api/updatePress' />
  </div>
}

export default ListOfPress;