
const axios = require('axios');
const url = 'https://static.sheeranalyticsandinsights.com';
// const url = 'http://localhost:8000';

const headers = {
  'Content-Type': 'application/json'
}

export function post(apiURL, data, callback) {
  new Promise((resolve, reject) => {
    axios.post(url + apiURL, data, {
      headers: headers
    })
      .then(function (response) {
        console.log('response...', response);
        if (response.status === 200) {
          callback();
          resolve(response);
        } else {
          alert("Error, Please contact support team!")
          reject(response);
        }
      }).catch(function (response) {
        //handle error
        reject(response);
        console.log(response);
        alert("Error, Please contact support team!")
      });
  })
}

export async function get(apiURL, callback) {
  try {
    const response = await axios.get(url + apiURL);
    console.log(response);
    callback(response.data);
  } catch (error) {
    console.error(error);
  }
}

export async function uploadImagesAPI(data) {
  const URL = `${url}/api/uploadImages`;
  try {
    // data = await axios.post(URL, data, headers);

    data = await axios(URL, {
      method: 'POST',
      data: data.formData,
      headers: {
        "Content-Type": 'application/json'
      }
    });
    return data;
  } catch (error) {
    throw error;
  }
}