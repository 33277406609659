import React, { useEffect, useState } from 'react';

import { get, post } from './network/apiCalls';

import Modal from './Modal';

const ListOfReport = () => {
  const [listOfReport, setListOfReport] = useState([])
  const [modalShow, setModalShow] = useState(false);
  const [isEditMonth, setIsEditMonth] = useState(false);
  const [modalData, setModalData] = useState({});
  const url = '/api/updateReport';

  useEffect(() => {
    get('/api/report/fetchAll?limit=0', (result) => {
      setListOfReport(result.reports);
    });
  }, []);

  const showModal = (index, objKey, data, title, key, id) => {
    setModalShow(true);
    setModalData({ body: data || '', title: title, key: key, id: id, index: index, objKey: objKey });
  }

  const updateReportState = (modalData, data) => {
    const newListOfReport = [...listOfReport];
    newListOfReport[modalData.index][modalData.objKey] = data;
    setModalData({ ...modalData, ['body']: data });
    setListOfReport(newListOfReport);
  }

  const fetchReport = () => {
    get('/api/report/fetchAll?limit=' + document.getElementById("reportLimit").value, (result) => {
      setListOfReport(result.reports);
    });
  }

  const editMonth = (index, id) => {

    if (isEditMonth) {
      const monthValue = document.getElementById("month").value;
      const data = {
        ['month']: monthValue,
        ["id"]: id
      }

      post(url, data, (result) => {
        const newListOfReport = [...listOfReport];
        newListOfReport[index]['month'] = monthValue;
        setListOfReport(newListOfReport);
      });
    }

    setIsEditMonth(!isEditMonth);
  }

  return <div>
    <h3>List Of Report</h3>
    <select id="reportLimit">
      <option value="0">0 - 100</option>
      <option value="100">101-200</option>
      <option value="200">201-300</option>
      <option value="300">301-400</option>
      <option value="All">All</option>
    </select>
    <button onClick={fetchReport}>Fetch Report</button>
    <br /><hr />
    <table className="table">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Title</th>
          <th scope="col">Meta Description</th>
          <th scope="col">Meta Keywords</th>
          <th scope="col">Meta Title</th>
          <th scope="col" style={{ "maxWidth": "300px" }}>Description</th>
          <th scope="col" style={{ "maxWidth": "300px" }}>TOC</th>
          <th scope="col" style={{ "maxWidth": "300px" }}>Analysis</th>
          <th scope="col">Published Date</th>
        </tr>
      </thead>
      <tbody>
        {
          (listOfReport || []).map((report, index) => {
            return <tr key={report.id}>
              <th scope="row">{report.id}</th>
              <th onClick={() => showModal(index, 'title', report.title, 'Title', 'title', report.id)} scope="row">{(report.title || '').substring(0, 200) + '...'}</th>
              <td onClick={() => showModal(index, 'meta_description', report.meta_desc, 'Meta Description', 'metaDescription', report.id)}>{report.meta_desc}</td>
              <td onClick={() => showModal(index, 'meta_keywords', report.meta_keywords, 'Meta Keywords', 'metaKeywords', report.id)}>{report.meta_keywords}</td>
              <td onClick={() => showModal(index, 'meta_title', report.meta_title, 'Meta Title', 'metaTitle', report.id)}>{report.meta_title}</td>
              <td onClick={() => showModal(index, 'description', report.description, 'Description', 'reportDescription', report.id)} style={{ "maxWidth": "300px" }}>{(report.description || '').substring(0, 200) + '...'}</td>
              <td onClick={() => showModal(index, 'table_contents', report.table_contents, 'TOC', 'tableOfContent', report.id)} style={{ "maxWidth": "300px" }}>{(report.table_contents || '').substring(0, 200) + '...'}</td>
              <td onClick={() => showModal(index, 'analysis', report.analysis, 'Analysis', 'reportAnalysis', report.id)} style={{ "maxWidth": "300px" }}>{(report.analysis || '').substring(0, 200) + '...'}</td>
              <td>{isEditMonth ? <input id="month" /> : report.month} <button onClick={() => editMonth(index, report.id)}>{`${isEditMonth ? 'Save' : 'Edit'} Month`}</button></td>
            </tr>
          })
        }
      </tbody>
    </table>
    <Modal
      show={modalShow}
      modaldataprops={modalData}
      updatereportstate={updateReportState}
      onHide={() => setModalShow(false)}
      url={url} />
  </div>
}

export default ListOfReport;