import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Report from './Report';
import Press from './Press';
import ListOfReport from './ListOfReport';
import ListOfPress from './ListOfPress';
import UploadImages from './UploadImages';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function Home({ logout }) {

  const handleLogout = () => {
    localStorage.removeItem('isLogin');
    logout();
  }

  return (
    <Router>

      <div style={{ backgroundColor: "#80808008" }}>
        <nav class="navbar sticky-top navbar-expand-lg navbar-light bg-primary">
          <a class="navbar-brand" href="#">Admin Panel | Sheer Analytics & Insight</a>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
              <li class="nav-item">
                <Link className="nav-link" to="/">Home</Link>
              </li>
              <li class="nav-item">
                <Link className="nav-link" to="/report">Release Report</Link>
              </li>
              <li class="nav-item">
                <Link className="nav-link" to="/report-list">List Of Report</Link>
              </li>
              <li class="nav-item">
                <Link className="nav-link" to="/presses">Release Press</Link>
              </li>
              <li class="nav-item">
                <Link className="nav-link" to="/press-list">List Of Press</Link>
              </li>
              <li class="nav-item">
                <Link className="nav-link" to="/upload-images">Upload Images</Link>
              </li>
              <li class="nav-item">
                <Link className="nav-link" to="/home" onClick={handleLogout}>Logout</Link>
              </li>
            </ul>
          </div>
        </nav>
        <Switch>
          <Route path="/report">
            <Report />
          </Route>
          <Route path="/presses">
            <Press />
          </Route>
          <Route path="/report-list">
            <ListOfReport />
          </Route>
          <Route path="/press-list">
            <ListOfPress />
          </Route>
          <Route path="/upload-images">
            <UploadImages />
          </Route>
          <Route path="/">
            <Report />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default Home;
