import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

import { post } from './network/apiCalls';

const Login = ({ isUserLoggedIn }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async () => {
    post('/api/login', { username: username, password: password }, (result) => {
      localStorage.setItem('isLogin', true);
      isUserLoggedIn();
    });
  }

  return <div style={{ backgroundColor: "#80808008" }}>
    <nav className="navbar sticky-top navbar-expand-lg navbar-light bg-primary" style={{ "marginBottom": "30px" }}>
      <a className="navbar-brand" href="#">Admin Panel | Sheer Analytics & Insight</a>
    </nav>
    <div style={{ "margin": "30px" }}>
      <Form style={{ "width": "400px", "margin": "0 auto", "padding": "30px", "border": "2px solid blue" }}>
        <h3>Sign In</h3><br />
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control type="text" placeholder="Enter email" onChange={(e) => setUsername(e.target.value)} />
        </Form.Group>

        <Form.Group controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
        </Form.Group>
        <Button variant="primary" type="submit" onClick={handleLogin}>
          Submit
        </Button>
      </Form>
    </div>
  </div>
}

export default Login;