import React, { useState } from 'react';
import style from "./styles.js";
import { uploadImagesAPI } from './network/apiCalls';

import './loader.css';

const UploadImages = () => {
  const [photoslist, setphotoslist] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  function onFileChange(e) {
    if (!e) return;
    if (!e.target) return;
    if (!e.target.files) return;
    if (e.target.files.length <= 0) return;

    let filelist = photoslist.slice();

    var index = 0;
    for (var i = 0; i < e.target.files.length; i++) {
      let reader = new FileReader();
      reader.onload = (event) => {
        filelist.push({ 'image': event.target.result, 'path': e.target.files[index] });
        index++;
        filesloaded(index, e.target.files.length, e.target.name, filelist);
      };
      reader.readAsDataURL(e.target.files[i]);
    }
    e.persist();
  }

  function filesloaded(index, length, name, filelist) {
    console.log(filelist);
    if (index == length) {
      if (name == 'images') {
        setphotoslist(filelist);
      }
    }
  }

  function handleImageRemove(e) {
    let id = e.target.id;
    if (id.indexOf("removeimage") >= 0) {
      photoslist.splice(parseInt(id.replace('removeimage', '')), 1);
      setphotoslist(photoslist.slice());
    }
  }

  async function uploadImages() {
    if (photoslist.length > 0) {
      let formData = new FormData();

      for (let x = 0; x < photoslist.length; x++) {
        console.log(photoslist[x].path);
        formData.append('images', photoslist[x].path);
      }

      setIsLoading(true);
      const json = await uploadImagesAPI({ formData: formData });

      if (json.status === 200) {
        alert("Images uploaded successfully");
        setphotoslist([]);
      } else {
        alert("Image Upload Failed");
      }
      setIsLoading(false);

    } else {
      alert("Please select minimum one image")
    }
  }

  return <>
    {isLoading && <div id="cover-spin"></div>}

    <div className="clickable" style={style.uploadDiv} onClick={() => { document.getElementById('images').click() }}>
      <img
        style={style.editProfilePic}
        src="/lib/editProfilePic.png"
      />
      <img
        style={style.uploadAddIcon}
        src="/lib/add.png"
      />
    </div>
    <button style={{ "marginLeft": "200px", "marginTop": "20px", "marginBottom": "20px", "width": "300px" }} onClick={uploadImages}>Upload Images</button>

    <input type="file" accept="image/*" multiple="multiple" id="images" name="images" onChange={onFileChange} style={{ "display": "none" }} />
    {
      photoslist.map((data, index) => {
        return (
          <span className="uk-padding-remove">
            <img
              style={{
                height: "200px",
                width: "300px",
                borderRadius: "10px",
                paddingLeft: "0px",
                marginLeft: "15px",
                marginTop: "15px"
              }} key={Math.random()} src={data.image} />
            <span className="floatbottomright clickable" id={"removeimage" + index} onClick={handleImageRemove}>
              <svg id={"removeimage" + index} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="15" height="20" fill="white" stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                <path d="M2 16 L30 16" />
              </svg>
            </span>
          </span>
        )
      })
    }
  </>
}

export default UploadImages;