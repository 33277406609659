import React, { useState } from 'react';

import Home from './Home';
import Login from './Login';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [isLogin, setIsLogin] = useState(localStorage.getItem('isLogin'));

  const isUserLoggedIn = () => {
    setIsLogin(true);
  }

  const logout = () => {
    setIsLogin(false);
  }

  return (
    isLogin ? <Home logout={logout} /> : <Login isUserLoggedIn={isUserLoggedIn} />
  );
}

export default App;
