import './mainstyles.css';
const style = {
  maingradStyle: {
    backgroundImage: "linear-gradient(to right,#fd267d,#ff7356)"
  },
  redgradStyle: {
    backgroundImage:
      "linear-gradient(to top left,rgba(247,197,152,1),rgba(255,136,134,1))"
  },
  purplegradStyle: {
    backgroundImage:
      "linear-gradient(to top left,rgba(249,180,189,1),rgba(149,115,219,1))"
  },
  bluegradStyle: {
    backgroundImage:
      "linear-gradient(to top left,rgba(68,222,197,1),rgba(78,188 ,250,1))"
  },
  greengradStyle: {
    backgroundImage:
      "linear-gradient(to right,rgba(75 ,235 ,202,1),rgba(43 ,230 ,174,1))",
    height: "28px"
  },
  whitetextStyle: { color: "white" },
  mainSectionStyle: { background: "#f1f5f6" },
  borderblackStyle: {
    border: "2px solid rgba(159 ,165 ,183 ,1)",
    color: "rgba(83,93,126 ,1)"
  },
  sidebarIcon: { height: 15, width: 15 },
  EditButtonFontSize: { fontSize: "0.8em" },
  EditButtonFloat: {
    position: "absolute",
    padding: "8px",
    // width: "2em",
    // marginTop: "-10%",
    // marginLeft: "60%",
    marginTop: "-17%",
    marginLeft: "56%",
    padding: "8px"
  },
  TextTransform: {
    textTransform: "uppercase"
  },
  PannelHeader: {
    display: "block",
    fontSize: ".9rem",
    lineHeight: "1.4",
    color: "rgb(109 114 120)",
    overflow: "hidden",
    fontWeight: "600"
  },
  AddProfileHeader: {
    "textTransform": "uppercase",
    "display": "block",
    "fontSize": "1.3em",
    "lineHeight": "1.4",
    "color": "rgb(109, 114, 120)",
    "overflow": "hidden",
    "fontWeight": "600",
  },
  Width50: {
    "width": "50%"
  },
  ProfileHeader: {
    display: "flex",
    marginBottom: "43px"
  },
  ProfileAddButton: {
    width: "9em",
    fontSize: "1.4em"
  },
  borderBottom: {
    marginLeft: "20px",
    paddingLeft: "0px",
    borderBottom: "1px solid #e5e5e5",
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "15px"
  },
  profileName: {
    color: "rgb(83,93,126)",
    fontSize: "15pt",
    textTransform: "uppercase"
  },
  uploadDiv: {
    backgroundColor: "white",
    height: "130px",
    width: "101px",
    borderRadius: "10px",
    position: "relative",
    marginLeft: "15px"
  },
  uploadAddIcon: {
    height: "24pt",
    width: "24pt",
    position: "absolute",
    bottom: "-11px",
    right: "-11px"
  },
  editProfilePic: {
    height: "25pt",
    width: "23pt",
    top: "46px",
    left: "34px",
    position: "absolute",
  }
};
export default style;
