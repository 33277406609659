

import React, { useEffect, useState } from "react"
import { useForm, Controller } from 'react-hook-form';

import { post, get } from './network/apiCalls';

import './report.css';

// The following component is an example of your existing Input Component 
const Input = ({ label, name, register, required, placeholder }) => (
  <>
    <label>{label}</label>
    <input name={name} ref={register({ required })} className="form-control mb-2" placeholder={placeholder} />
  </>
);

const Textarea = ({ label, name, register, required }) => (
  <>
    <label>{label}</label>
    <textarea
      type="textarea"
      className="form-control mb-2"
      // placeholder="Message"
      // aria-label="Your Message"
      rows="8"
      ref={register}
      name={name}
    />
  </>
);

const Select = ({ register, options, label, name, ...rest }) => {
  return (
    <>
      <label>{label}</label>
      <select name={name} ref={register} {...rest}>
        {options.map(value => (
          <option value={value.value}>{value.label}</option>
        ))}
      </select>
    </>
  );
}

export default function Report() {
  const { register, handleSubmit, errors, reset, control } = useForm();
  const [categoryList, setCategoryList] = useState([{ label: '-- Select --', value: 0 }]);
  const [subCategoryList, setSubCategoryList] = useState([{ label: '-- Select --', value: 0 }]);

  useEffect(() => {
    get('/api/categoryList', (result) => {
      let categories = [{ label: '-- Select --', value: 0 }];

      (result.list || []).map(category => {
        categories.push({ label: category.cat_name, value: category.id });
      });

      setCategoryList(categories);
    });

  }, [])

  const onSubmit = async data => {
    try {
      console.log(".....data", data);
      await post('/api/savePress', data, () => {
        reset();
        alert("Press Save successfully!");
      })
    } catch (error) {
      alert("Error");
      console.log('errors....', error);
    }
  };

  return (
    <div className="container">
      <div className="card mt-5 p-3">
        <form onSubmit={handleSubmit(onSubmit)} type="POST">
          <h3 className="mb-5">Add New Press</h3>
          <div className="row">
            <div className="col-sm-12 mb-2">
              <Input label="Password" name="password" register={register} required={true} />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 mb-2">
              <label className="col-sm-4 mb-2">Industry Category:</label>
              <Controller
                className="col-sm-7 mb-2"
                as={<Select options={categoryList} />}
                control={control}
                rules={{ required: true }}
                onChange={([selected]) => {
                  // Place your logic here

                  get('/api/subCategoryList/?industryCategoryId=' + selected.currentTarget.value, (result) => {
                    let categories = [{ label: '-- Select --', value: 0 }];

                    (result.list || []).map(category => {
                      categories.push({ label: category.sector_name, value: category.id });
                    });

                    setSubCategoryList(categories);
                  });

                  return selected;
                }}
                name="industryCategory"
              // defaultValue={1}
              />
            </div>
            <div className="col-sm-6 mb-2">
              <label className="col-sm-4 mb-2">Industry Sub Category:</label>
              <Controller
                className="col-sm-7 mb-2"
                as={<Select options={subCategoryList} />}
                control={control}
                rules={{ required: true }}
                onChange={([selected]) => {
                  // Place your logic here

                  return selected;
                }}
                name="industrySubCategory"
              // defaultValue={1}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 mb-2">
              <Input label="Title" name="title" register={register} required={true} />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 mb-2">
              <Input label="Meta Title" name="metaTitle" register={register} required={true} />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 mb-2">
              <Textarea label="Meta Description" name="metaDescription" register={register} required={true} />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 mb-2">
              <Input label="Meta Keywords" name="metaKeywords" register={register} required={true} />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 mb-2">
              <Textarea label="Body" name="body" register={register} required={true} />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 mb-2">
              <Textarea label="Quotation" name="quote" register={register} required={true} />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 mb-2">
              <Input label="Author Name" name="name" register={register} required={true} />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 mb-2">
              <Input label="Slug" name="slug" register={register} required={true} />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 mb-2">
              <Input label="Link" name="link" register={register} required={true} placeholder="Please enter REPORT LINK(https://www.sheeranalyticsandinsights.com/market-report-research/{report slug}) for this press here" />
            </div>
          </div>
          <button type="button " className="btn btn-dark btn-sm btn-block btn-outline-light mt-2 mb-2">Save Press</button>
        </form>
      </div>
    </div>
  )
}
