import React, { useState, useRef } from 'react';
import { Modal as Modal, Button } from 'react-bootstrap';

import { post } from './network/apiCalls';

const ModalWrap = (props) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const bodyRef = useRef();

  const updateReport = () => {
    const data = {
      [props.modaldataprops.key]: bodyRef.current.value,
      ["id"]: props.modaldataprops.id
    }

    post(props.url, data, (result) => {
      props.updatereportstate(props.modaldataprops, data[props.modaldataprops.key]);
      setIsEditMode(false);
    });
  }

  return <Modal
    {...props}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        {props.modaldataprops.title}
        {
          isEditMode ?
            <span>
              <button type="button" onClick={() => { setIsEditMode(false) }} class="btn btn-primary btn-lg" style={{ "marginLeft": "30px" }}>
                <span class="glyphicon glyphicon-star" aria-hidden="true"></span> View
              </button>
              <button type="button" onClick={() => { updateReport() }} class="btn btn-primary btn-lg" style={{ "marginLeft": "30px" }}>
                <span class="glyphicon glyphicon-star" aria-hidden="true"></span> Save & View
              </button>
            </span>
            :
            <button type="button" onClick={() => { setIsEditMode(true) }} class="btn btn-primary btn-lg" style={{ "marginLeft": "30px" }}>
              <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M11.293 1.293a1 1 0 0 1 1.414 0l2 2a1 1 0 0 1 0 1.414l-9 9a1 1 0 0 1-.39.242l-3 1a1 1 0 0 1-1.266-1.265l1-3a1 1 0 0 1 .242-.391l9-9zM12 2l2 2-9 9-3 1 1-3 9-9z" />
                <path fill-rule="evenodd" d="M12.146 6.354l-2.5-2.5.708-.708 2.5 2.5-.707.708zM3 10v.5a.5.5 0 0 0 .5.5H4v.5a.5.5 0 0 0 .5.5H5v.5a.5.5 0 0 0 .5.5H6v-1.5a.5.5 0 0 0-.5-.5H5v-.5a.5.5 0 0 0-.5-.5H3z" />
              </svg> Edit
          </button>
        }
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
        {
          isEditMode ?
            <textarea rows="100" cols="100" ref={bodyRef}>{props.modaldataprops.body}</textarea>
            :
            <div
              dangerouslySetInnerHTML={{
                __html: props.modaldataprops.body
              }}></div>
        }
      </p>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={props.onHide}>Close</Button>
    </Modal.Footer>
  </Modal>
}

export default ModalWrap;